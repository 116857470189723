<template>
    <div class="home-container">
        <div class="banner-box" v-if="width>750">
                 <a :href="bannerList.data.url" target="_blank" v-if="bannerList.data.type==2">
                    <img :src="bannerList.data.object" alt="">
                    <div class="title" v-if="bannerList.data.is_show_title==1">
                        <div>{{getters.language.value==='cn'?bannerList.data.title_cn:bannerList.data.title_en}}</div>
                    </div>
                </a>
               <div style="height:100%;overflow: hidden;" v-else>
                   <div class="title" v-if="bannerList.data.is_show_title==1">
                        <div>{{getters.language.value==='cn'?bannerList.data.title_cn:bannerList.data.title_en}}</div>
                    </div>
                    <video  width="100%" :src="bannerList.data.object" muted='true' :loop='true'  autoplay ></video>
               </div>
        </div>
        <div class="img-box" v-else>
            <img src="@/assets/images/image2.jpg" alt="" />
        </div>
        <template v-if="introductionList.data.about">
            <div class="maintitle">
                {{getters.language.value==='cn'?introductionList.data.about.title_cn:introductionList.data.about.title_en}}
            </div>
            <div class="intro" style="text-align:left;">
            {{getters.language.value==='cn'?introductionList.data.about.content_cn:introductionList.data.about.content_en}}
            </div>
        </template>
        <div class="introlist" v-if="introductionList.data.harvest&&introductionList.data.harvest.length">
            <div class="introitem" v-for="item in introductionList.data.harvest" :key="item.id">
                <div class="default" @click="yulangClick" id="yulangClick">
                    <img :src="getters.language.value==='cn'?item.title_img_cn:item.title_img_en" alt="">
                    <div class="name">{{getters.language.value==='cn'?item.title_cn:item.title_en}}</div>
                </div>
                <div class="active animation" ref="active">
                    <div class="name" :style="{marginBottom:getters.language.value==='cn'?'34px':'20px'}">{{getters.language.value==='cn'?item.title_cn:item.title_en}}</div>
                    <div class="con" :style="{lineHeight:getters.language.value==='cn'?'27px':'23px'}">{{getters.language.value==='cn'?item.content_cn:item.content_en}}</div>
                </div>
            </div>
        </div>
        <div class="award-category">
            <div class="maintitle" style="line-height:56px;margin-top:90px">{{ t('title.AwardCategory')}}</div>
            <div class="title2">{{ t('title.AwardCon')}}</div>
            <div class="conter" v-if="introductionList.data.awards&&introductionList.data.awards.length">
                <div v-for="item in introductionList.data.awards" :key="item.id">
                     <img :src="getters.language.value==='cn'?item.title_img_cn:item.title_img_en" alt="">
                    <div>{{getters.language.value==='cn'?item.content_cn:item.content_en}}</div>
                </div>
            </div>
        </div>
        <div class="character">
              <div class="maintitle" style="line-height:56px;margin-top:90px">{{ t('title.next')}}</div>
              <ul v-if="globalAwardsList.data.length">
                <li v-for="item in globalAwardsList.data.slice(0,12)" :key="item">
                    <a :href="item.url" target="_blank">
                        <div class="default">
                            <img :src="item.avatar" alt="">
                        </div>
                        <div class="active">
                        <div>
                            <div class="name">{{getters.language.value==='cn'?item.name_cn:item.name_en}}</div>
                            <div class="describe">{{getters.language.value==='cn'?item.title_cn:item.title_en}}</div>
                        </div>
                        </div>
                    </a>
                </li>
              </ul>
        </div>
        <div class="content-more">
            <el-row>
                <el-col :span="11" style="margin-right:30px">
                    <div class="head" style="border-bottom: 5px solid #ED3524;position: relative;">
                       <span style="background: #ED3524;">{{ t('button.News') }}</span>
                        <el-link @click="go_more('1')">{{ t('button.More') }}</el-link>
                    </div>
                    <ul class="news-content">
                        <li v-for="item in getters.newsListdata.value.data" :key="item.id">
                            <a  :href="item.jump_url" target="_blank">{{item.title}}</a>
                        </li>
                    </ul>
                </el-col>
                <el-col :span="11" style="margin-left:30px">
                    <div class="head" style="border-bottom: 5px solid#222222;position: relative;">
                       <span style="background:#222222;">{{ t('button.Video') }}</span>
                        <el-link  @click="go_more('2')">{{ t('button.More') }}</el-link>
                    </div>
                    <ul class="video-content">
                        <li v-for='item in listData' :key='item.id' @click="go_videoDetails(item)">
                            <img src="@/assets/images/video.png" alt=""  style="position: absolute; top: 66px;left: 107px;"/>
                            <div class="img-div"> <img :src="item.cover_img_url" alt=""></div>
                            <div class="title-div">{{getters.language.value==='cn'?item.title_cn:item.title_en}}</div>
                        </li>
                    </ul>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance, onBeforeMount ,reactive,computed} from 'vue'
import elementResizeDetectorMaker from 'element-resize-detector'
import { Ajax } from '@/utils'
import {useGetters} from '@/store/use.js'
import { useI18n } from 'vue-i18n'
import { useRouter } from "vue-router";
import store from '@/store'
export default {
    components: {
    },
    setup() {
        const { t, locale } = useI18n()
       const getters=useGetters(['language','newsListdata'])
        const route = useRouter();
        const bannerList=reactive({data:{}});
        const introductionList=reactive({data:{}});
        const globalAwardsList=reactive({data:{}});
        const listData=ref([]);
        const getbanner=()=>{//banner数据
            Ajax.POST({
                 url: '/api/website/banner',
            }).then(res=>{
                if(res.data.code===10000){
                    bannerList.data=res.data.data
                }
            })
        }
        const width=ref(document.body.clientWidth)
         window.onresize = () => {
            return (() => {
            width.value=document.body.clientWidth;
            })();
        };
        const getintroduction=()=>{//授勋类别数据
            Ajax.POST({
                 url: '/api/website/introduction',
            }).then(res=>{
                if(res.data.code===10000){
                    introductionList.data=res.data.data
                }
            })
        }
         const getglobalAwards=()=>{//下一个会是你吗数据
            Ajax.POST({
                 url: '/api/website/globalAwards',
            }).then(res=>{
                if(res.data.code===10000){
                    globalAwardsList.data=res.data.data
                }
            })
        }
        const yulangClick=()=>{
            
        }
        //新闻资讯数据
        store.dispatch("app/getnews_list",{per_page:11,current_page:1,language:getters.language.value=='cn'?1:2});
         //精彩视频数据
        store.dispatch("app/getvideo_list",{per_page:4,current_page:1,awards_type:''}).then(res=>{
            listData.value=res.data;
        })
        const go_videoDetails=(item)=>{
            sessionStorage.setItem('activeIndex',3);
            store.dispatch('app/setactiveIndex', 3);
            if(item.is_three==1)
                 window.open('/video-details?id='+item.id+'&to=index')
            else  window.open(item.jump_url)
            // route.push('/video-details?id='+id)
        }
        const go_more=(num)=>{//跳转资讯页面
            sessionStorage.setItem('activeIndex',3);
            store.dispatch('app/setactiveIndex', 3);
            route.push('/information');
            sessionStorage.setItem('ind',num);
            store.dispatch('app/getad_list', {num:5,type:''})
            // route.push('/information?ind='+num);
        }
        getbanner();
        getintroduction();
        getglobalAwards();
        return {
             t,
             width,
            getters,
            bannerList,
            introductionList,
            globalAwardsList,
            listData,
            yulangClick,
            go_more,
            go_videoDetails
        }
    }
};
</script>

<style lang="less" scoped>
    .home-container{
        width: 100%;
        padding-bottom: 80px;
        .img-box{display: none;}
        .banner-box{
            height: 675px;
            width: 100%;
            min-width: 1220px;
            position: relative;
            text-align: center;
            img{
                width: 100%;
                height: 100%;
                // object-fit: contain;
            }
            video{width: 100%;}
            .title{
               position: absolute;
                // top: 295px;
                width: 100%;
                // left: 17.9%;
                font-size: 53px;
                // font-family: PingFangSC-Semibold, PingFang SC;
                // font-weight: 600;
                color: #FFFFFF;
                line-height: 74px;
                bottom: 200px;
                // text-shadow: 1px 2px 3px #000000;
                div{
                    width: 1220px;margin: 0 auto;
                }
                }
        }
        .maintitle{
            font-size: 40px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            margin-top: 100px;
            text-align: center;
            position: relative;
            line-height: 50px;
        }
        .maintitle:after,.title1:after{
            content: '';
            width: 58px;
            height: 5px;
            background: #ED3524;
            position: absolute;
            left: 50%;
            bottom: -24px;
            margin-left: -29px;
        }
        .intro{
            width: 1200px;
            margin: 64px auto 0;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4A4A4A;
            line-height: 34px;
            letter-spacing: 1px;
            text-align: center;
        }
        .introlist{
            width: 100%;
            min-width: 1220px;
            display: flex;
            margin-top: 46px;
            position: relative;
            .introitem{
                flex: 1;
                .default{
                    height: 280px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .name{
                        font-size: 22px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 24px;
                    }
                }
                .active{
                    display: none;
                    padding: 38px 30px 0;
                   
                    .name{
                        font-size: 24px;
                        font-weight: 700;
                        font-family: PingFangSC-Regular, PingFang SC;
                        color: #FFFFFF;
                        margin-bottom: 34px;
                        text-align: center;
                    }
                    .con{
                        font-size: 17px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                       line-height: 27px;
                    }
                }
                 @media screen and (min-width: 750px){
                     .active{
                        position: absolute;
                        bottom: 0px;
                        width: 25%;
                      
                        transform:translate(0px,0px);
                     }
                 }
            }
            @keyframes active_move
            {
                from {top:300px;}
                to {top:10px;}
            }
            @-webkit-keyframes active_move /* Safari and Chrome */
            {
                from {top:300px;;}
                to {top:10px;}
            }
            .introitem:hover{
                .active{
                    display: block;
                }
                .default{
                    display: none;
                }
                @media screen and (min-width: 750px){
                     .active{
                        animation-duration: 1s;
                        animation-fill-mode: both;
                        animation-name: active_move;
                        }
                 }
            }
            .introitem:nth-of-type(odd){
                background: #222222;
            }
            .introitem:nth-of-type(even){
                background: #ED3524;
            }
        }
        .award-category{
            width: 100%;
            text-align: center;
            margin:  80px auto;
            font-weight: 400;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #4A4A4A;
          
            .title2{
                width: 1200px;
                margin: 64px auto 46px;
                height: 29px;
                font-size: 18px;
                line-height: 28px;
            }
            .conter{
                width: 1200px;
                margin: 0 auto;
                display: flex;
                img{
                    width:172px;
                    height: 42px;
                    object-fit: contain;
                    margin: 20px 0 33px;
                }
                >div{
                    width: 240px;
                    min-height: 300px;
                    // display: inline-block;
                    padding: 20px;
                    border: 1px solid #EEEEEE;
                    div{
                       line-height: 28px;
                        font-size: 15px; 
                        text-align: left;
                    }
                }
                >div:hover{ 
                    background: #FFFFFF;
                    box-shadow: 0px 30px 50px 0px rgba(207, 190, 195, 0.5);
                    border: 1px solid #ED3524;
                }
            }
        }
         .character{
            width:100%;
            margin: 0 auto;
            width:1200px;
            .el-col-11{width: 49%;}
            ul{
                width: 1240px;
                margin: 70px auto;
                li{
                    // width: 25%;
                    // width:20%;
                    width: 292px;
                    height: 292px;
                    padding: 0px;
                    display: inline-block;
                    text-align: center;
                    margin:0 11px 11px 0;
                    position: relative;
                    cursor: pointer;
                    .default{height: 100%;width:100%;overflow: hidden;
                        img{
                            width: 100%;
                            height: 100%;
                            // object-fit: cover;
                            transition:all 1s;
                        }
                    }
                    .active{
                        display: none;
                        // width: calc(100% - 20px);
                        // height: 95%;
                        width: 292px;
                        height: 292px;
                        position: absolute;
                        top: 0px;
                        z-index: 99;
                       background: rgba(237, 53, 36, 0.5);
                        >div{
                            position: absolute;
                            bottom: 15px;
                            text-align: center;
                            width: 100%;
                            color: #fff;
                            font-size: 14px;
                        }
                        .name{
                                font-size: 20px;
                                margin-bottom: 10px;
                                font-weight: 600;
                                color: #FFFFFF;
                                line-height: 28px;
                                text-shadow: 1px 2px 3px #000000;
                        }
                        .describe{
                            width: 232px;
                            min-height: 44px;
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 22px;
                            text-shadow: 1px 2px 2px #000000;
                            margin: 0 auto;
                            // overflow: hidden;
                            white-space: normal;
                            display: -webkit-box;
                            // word-break: break-all;
                            text-overflow: ellipsis;
                             -webkit-box-orient: vertical;
                            // -webkit-line-clamp:2;
                        }
                    }
                    
                }
                li:hover{
                    .active{
                        display: block;
                        // transform: scale(1.1,1.1);
                    }
                    img{
                        transform: scale(1.1,1.1);
                    }
                }
            }
        }
        .content-more{
              width:1200px;
              margin: 79px auto 0;
               font-family: PingFangSC-Medium, PingFang SC;
            .el-row{
                 width: 100%;
                 .el-col-11{max-width: 49.1% !important;flex: 0 0 47.5%;}
                .head{
                    height: 55px;
                    margin-bottom: 40px;
                    span{
                        width: 126px;
                        height: 50px;
                        font-size: 24px;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 33px;
                        padding: 9px 15px;
                        position: absolute;
                        bottom: -1px;
                        text-align: center;
                }
                a{
                        position: absolute;
                        right: 0px;
                        bottom: 10px;
                        width: 56px;
                        height: 20px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                        line-height: 20px;
                        // text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
                }
                a::after{ border: none;}
                a:hover{
                    color: #ED3524;
                }
            }
            .news-content{
                width: 570px;
                li{
                     margin-bottom: 30px;
                    a{
                        display: block;
                        overflow : hidden ;//超出一行文字自动隐藏
                        text-overflow :ellipsis;//文字隐藏后添加省略号
                        white-space : nowrap ;//强制不换行
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 16px;
                        cursor: pointer;
                        color: #333333;
                        text-decoration: none;
                    }
                    a:hover{
                        color: #ED3524;
                    }
                }
                
            }
            .video-content{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: start;
                 li{
                    position: relative;
                     width: 275px;
                    height: 248px;
                    margin-bottom: 20px;
                    cursor: pointer;
                     border: 1px solid #fff;
                    .img-div{
                            width: 100%;
                            height: 180px;
                            text-align: center;
                            img{
                                width: 100%;
                                height: 100%;
                                // object-fit: contain;
                            }
                        }
                        .title-div{
                            margin: 10px 8px 14px;
                            width: 249px;
                            height: 44px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #333333;
                            line-height: 22px;
                            display: -webkit-box;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            word-wrap: break-word;
                        }
                 }
                 li:nth-child(odd){margin-right: 20px;}
                 li:hover{
                    background: #FFFFFF;
                    box-shadow: 0px 13px 20px 0px #E1E1E1;
                    border: 1px solid #ED3524;
                 }
            }
            }
            
            
        }
    }
    @media screen and (min-width: 375px) and (max-width: 750px){
        .home-container{
            .banner-box{
                display: none;
                // height: 230px;
                // min-width: 100%;
                // video{height: 100%;}
                // .title{
                //     font-size: 30px;
                //     line-height: 30px;
                //     margin-top: -30px;
                //     top: 106px;
                //     width: 100%;
                //     div{width: 100%;}
                // }
            }
             .img-box{display: block;img{width: 100%;}}
            .maintitle{font-size: 25px;}
            .intro{width: 90%; margin: 45px auto 0; }
            .introlist{
                display: block;
                min-width: 100%;
                // height: 450px;
                .introitem{
                    // width: 50%;float: left;
                 .default{
                     min-height: 210px;
                     height:auto;
                img{width: 20%;}
                }
                .active{
                   padding: 20px 0px !important;
                    min-height: 210px;
                    .name{margin-bottom: 22px;}
                    .con{padding: 0 20px;}
                }
                }
               
            }
            .award-category{
                margin: 30px auto;
                .maintitle{margin-top: 30px; }
                .title2{width: 90%;height: auto;}
                .conter{
                    width: 90%;display: block;
                    >div{width: 100%;margin-bottom: 30px;min-height: 200px;img{margin: 20px 0;}}
                    }
            }
            .character{
                width: 100%;
                .maintitle{margin-top: 50px;}
                ul{
                    width: 100%;
                    text-align: center;
                    li{
                        width: 50%;
                        padding: 0;
                        height: 180px;
                        width: 47%;
                        .default{width: 100%;}
                    }
                    // li:nth-child(odd){padding-right: 10px;}
                    li:nth-child(2n){margin-right: 0;}
                    li:hover{
                          .active{
                                display: none;
                                transform: scale(1);
                            }
                            img{
                                transform: scale(1);
                            }
                        }
                }
            }
            .content-more{
                width: 100%;
                margin: 69px auto 0;
                .el-row{
                    width: 95%;
                   margin: 0 auto;
                   .head{margin-bottom: 20px;
                   span{
                       height: 40px;padding: 4px 15px;font-size: 20px;
                   }
                   }
                   .news-content{width: 100%;}
                    .el-col-11{
                        margin-right: 0px !important;
                        max-width: 100% !important;
                        flex: 100%;
                        li{margin-bottom: 15px;}
                    }
                     .el-col-11:nth-child(2){
                         margin-top: 40px;
                         margin-left: 0px !important;
                         li{
                             width: 47.8%; height: 202px;
                             >img{top: 40px !important;left: 59px !important;}
                             .title-div{width: 153px;height: 42px;}
                             }
                             li:nth-child(odd){margin-right: 15px;}
                         .img-div{
                             height: 130px;
                            //  img{object-fit: contain;}
                         }
                     } 
                }
            }
        }
    }

    @media screen and (min-width: 300px) and (max-width: 375px){
        .home-container{
            .banner-box{
                .title{
                    font-size: 15px;
                    line-height: 15px;
                    margin-top: -15px;
                }
            }
        }
    }
</style>